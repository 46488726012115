<template>
  <div>

    <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
        <meta http-equiv="Content-Style-Type" content="text/css"/>
        <meta name="generator" content="Aspose.Words for .NET 17.1.0.0"/>
        <title></title></head>
      <body>
        <div><p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">辰汇用户注册协议与隐私政策</span>
             </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">欢迎您来到辰汇</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">&#xa0;</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">请您仔细阅读以下条款</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">如果您对本协议的任何条款表示异议</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您可以选择不进入辰汇</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">当您注册成功后</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">无论是进入辰汇</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">还是在辰汇上发布任何内容</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">即</span><span
            style="font-family:'MS Gothic'">「</span><span style="font-family:'Microsoft JhengHei'">内容</span><span
            style="font-family:'MS Gothic'">」</span><span style="font-family:'Courier New'">），</span><span
            style="font-family:'Microsoft JhengHei'">均意味着您</span><span style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">以下称为</span><span style="font-family:'MS Gothic'">「</span><span
            style="font-family:'Microsoft JhengHei'">用户</span><span style="font-family:'MS Gothic'">」</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">包括但不限于浏览用户</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">个人作者</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">组织机构等</span><span style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">完全接受本协议项下的全部条款</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">——</span><span style="font-family:'Microsoft JhengHei'">总则</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">如用户完成注册程序</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">即表示用户与辰汇</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">包括且不限于网站</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">手机应用等多平台</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">就其所提供的服务达成协议</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">自愿接受本服务协议的所有内容</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">此后</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">用户不得以未阅读本服务协议内容为由进行任何形式的抗辩</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">用户注册成功后</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">辰汇将给予每个用户一个用户账号及对应的密码</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">该用户账号和密码由用户自己负责保管</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">用户应当对以其用户账号进行的所有活动和发表的全部内容承担法律责任</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">一</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">使用规则</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">1.1 </span><span
            style="font-family:'Microsoft JhengHei'">用户须对其在辰汇的注册信息的真实性</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">合法性</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">有效性承担责任</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">用户不得以任何方式</span><span
            style="font-family:'Courier New'">(</span><span
            style="font-family:'Microsoft JhengHei'">包括但不限于冒用他人姓名</span><span
            style="font-family:'Courier New'">/</span><span style="font-family:'Microsoft JhengHei'">其他组织机构名称</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">头像</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">昵称等</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">冒充他人或其他组织机构</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">不得利用他人</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">其他组织机构的名义发布任何信息</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">不得恶意使用注册账户导致其他用户将其误认为他人或其他组织机构</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">如用户违反前述约定</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">辰汇有权随时终止对该用户提供服务</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">收回其账号</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并由该用户独立承担由此而产生的一切法律责任</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">1.2 </span><span
            style="font-family:'Microsoft JhengHei'">为更好地保护个人用户及组织机构在辰汇上的合法权益</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">用户注册成功后</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">可进入辰汇站内</span><span
            style="font-family:'Courier New'">“</span><span style="font-family:'Microsoft JhengHei'">个人主页</span><span
            style="font-family:'Courier New'">”</span><span
            style="font-family:'Microsoft JhengHei'">补充完善真实的个人信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">包括但不限于姓名</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">手机</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">邮箱</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">微信账户</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">企业信息</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">1.3 </span><span
            style="font-family:'Microsoft JhengHei'">辰汇是一个资讯信息分享</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">传播</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">获取及交流的平台</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">用户通过辰汇发表的信息</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">包括投稿</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">评论</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">及使用辰汇相应内容</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">技术服务等</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">为公开的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">其他第三方均可以通过辰汇获知用户发表的信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">用户对任何信息的发表即承认该信息是公开的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并独立对此行为承担法律责任</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">任何用户不愿被其他第三方获知的信息都不应该在辰汇上进行发表</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">用户向辰汇投稿的情况下</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">应当视为同意辰汇有权对其稿件进行相应的处理</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">包括但不限于删减</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">编辑</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">修改</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">翻译</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">改编</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">加注或配图等</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">或不予发表</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-left:25.2pt; margin-bottom:0pt; text-indent:-25.2pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">1.4 </span><span style="font-family:'Microsoft JhengHei'">用户承诺不得以任何方式利用辰汇直接或间接从事违反中国法律</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">法规以及社会公德的行为</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">辰汇有权对违反上述承诺的内容予以删除</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-left:25.2pt; margin-bottom:0pt; text-indent:-25.2pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">1.5 </span><span
            style="font-family:'Microsoft JhengHei'">用户同意将不会利用辰汇提供的服务制作</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">发布</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">转载或以其他方式传送含有下列内容之一的信息</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">反对宪法所确定的基本原则的</span><span style="font-family:'Courier New'">；</span>
          </p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">危害国家安全</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">泄露国家秘密</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">颠覆国家政权</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">破坏国家统一的</span><span style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">损害国家荣誉和利益的</span><span style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">煽动民族仇恨</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">民族歧视</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">破坏民族团结的</span><span style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">破坏国家宗教政策</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">宣扬邪教和封建迷信的</span><span style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">散布谣言</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">扰乱社会秩序</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">破坏社会稳定的</span><span style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">散布淫秽</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">色情</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">赌博</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">暴力</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">凶杀</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">恐怖或者教唆犯罪的</span><span style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">侮辱或者诽谤他人</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">侵害他人合法权益的</span><span style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">含有中国法律法规以及其他任何具有法律效力之规范所限制或禁止的其他内容的</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">含有辰汇认为不适合在辰汇展示的内容</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">1.6 </span><span
            style="font-family:'Microsoft JhengHei'">辰汇有权对用户使用辰汇的情况进行审查和监督</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">如用户在使用辰汇时违反任何上述规定</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">辰汇或其授权的人有权要求用户改正或直接采取一切必要的措施</span><span
            style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">包括但不限于更改或删除用户张贴的内容</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">暂停或终止用户使用辰汇发布或参与内容获取与交流的权利</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">以及删除用户账号</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">以减轻用户不当行为造成的影响</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">二</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">知识产权</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">辰汇是一个信息获取</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">分享</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">传播及交流的个性化资讯平台</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们尊重和鼓励辰汇用户创作和发布的内容</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">并承诺将保护知识产权作为辰汇运营的基本原则之一</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">2.1 </span><span
            style="font-family:'Microsoft JhengHei'">用户</span><span style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">包括不限于普通用户</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">个人作者</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">组织机构等</span><span style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">在辰汇上发表的全部原创文章信息内容及评论内容等</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">著作权均归用户本人所有</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">用户可授权第三方以任何方式使用</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">不需要得到辰汇的同意</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">但若信息内容经辰汇编辑</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">修改</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">翻译</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">改编或加注等</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">用户授权第三方使用前需得到辰汇同意</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">2.2 </span><span
            style="font-family:'Microsoft JhengHei'">辰汇提供的网络服务中包含的标识</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">版面设计</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">排版方式</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">文本</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">图片</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">图形等均受著作权</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">商标权及其它相关权利的法律法规保护</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">未经相关权利人</span><span
            style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">含辰汇及其他原始权利人</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">同意</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">上述内容均不得在任何平台被直接或间接发布</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">使用</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">出于发布或使用目的的改写或再发行</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">或被用于其他任何商业目的</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">2.3 </span><span
            style="font-family:'Microsoft JhengHei'">为了促进资讯信息的分享和传播</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">用户将其在辰汇上发表的全部内容</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">授予辰汇免费的</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">不可撤销的</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">非独家使用许可</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">辰汇有权将该内容用于辰汇及其合作方各种形态的产品和服务上</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">包括但不限于网站以及发表的应用或其他互联网产品</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">辰汇在与用户协商相关权益</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">包括且不限于署名</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">给予一定报酬等</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">并取得授权后</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">也有权将内容用于上述许可之外的其他合法用途</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">包括但不限于部分或者全部地复制</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">修改</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">改编</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">翻译</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">组装</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">分拆</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">推广</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">分发</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">广播</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">表演</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">演绎</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">出版</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">2.4 </span><span
            style="font-family:'Microsoft JhengHei'">第三方若出于非商业目的</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">欲将用户在辰汇上发表的内容转载在辰汇之外的地方</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">应当在作品正文开头的显著位置注明原作者姓名</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">给出原始链接</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">注明</span><span
            style="font-family:'MS Gothic'">「</span><span style="font-family:'Microsoft JhengHei'">来源辰汇</span><span
            style="font-family:'MS Gothic'">」</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并且不得擅自对信息内容进行修改演绎</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">若需要对信息内容进行修改</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">或用于商业目的</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">第三方应当参照上述</span><span
            style="font-family:'Courier New'">2.1</span><span
            style="font-family:'Microsoft JhengHei'">协议内容联系辰汇或用户获得单独授权</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">按照约定的方式使用该内容</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">2.5 </span><span
            style="font-family:'Microsoft JhengHei'">辰汇为用户提供</span><span style="font-family:'MS Gothic'">「</span><span
            style="font-family:'Microsoft JhengHei'">独家授权</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">禁止转载</span><span style="font-family:'MS Gothic'">」</span><span
            style="font-family:'Microsoft JhengHei'">的选项</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">除非获得原作者的单独授权</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">并邮件联系辰汇</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">邮箱</span><span
            style="font-family:'Courier New'">：info@suniversgroup.com），</span><span
            style="font-family:'Microsoft JhengHei'">任何第三方不得转载标注了</span><span
            style="font-family:'MS Gothic'">「</span><span style="font-family:'Microsoft JhengHei'">禁止转载</span><span
            style="font-family:'MS Gothic'">」</span><span style="font-family:'Microsoft JhengHei'">的内容</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">否则均视为侵权</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">2.6 </span><span
            style="font-family:'Microsoft JhengHei'">在辰汇发布创作或评论等内容</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">用户应保证其为著作权人或已取得合法授权</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并且该内容不会侵犯任何第三方的合法权益</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">如果第三方提出关于著作权的异议</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">辰汇有权根据实际情况整改或删除相关的内容</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并有权追究用户的法律责任</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">给辰汇或任何第三方造成损失的</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">用户应承担全部赔偿责任</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">2.7 </span><span
            style="font-family:'Microsoft JhengHei'">如果任何第三方侵犯了辰汇用户的相关权利</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">用户同意授权辰汇或其指定的代理人代表辰汇或该用户对该第三方提出警告</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">进行投诉</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">发起行政执法</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">提起诉讼</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">进行上诉</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">或谈判和解</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">并且用户同意在辰汇认为必要的情况下参与共同维权</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">三</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">作者认证</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">作者认证是辰汇对在辰汇站内发表过文章的用户真实身份的确认</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">获取辰汇作者认证标识</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">用户身份将更易于辨识</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">更加权威</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">更有可信度</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">同时也将获得更多的作者特权</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">用户可通过</span><span
            style="font-family:'Courier New'">“</span><span style="font-family:'Microsoft JhengHei'">辰汇</span><span
            style="font-family:'Courier New'">”—“</span><span style="font-family:'Microsoft JhengHei'">关于我们</span><span
            style="font-family:'Courier New'">”—“</span><span style="font-family:'Microsoft JhengHei'">辰汇认证</span><span
            style="font-family:'Courier New'">”</span><span style="font-family:'Microsoft JhengHei'">进入作者认证页面了解详细的认证规则与执行办法</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">鉴于互联网服务的特殊性</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">辰汇将可能根据自身的发展与规划对规则进行适当调整</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并保留对此的最终解释权</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">3.1 </span><span
            style="font-family:'Microsoft JhengHei'">独物用户</span><span style="font-family:'Courier New'">：</span><span
            style="font-family:'Microsoft JhengHei'">指享受独物服务的用户</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">四</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">侵权与维权规则</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">4.1 </span><span
            style="font-family:'Microsoft JhengHei'">处理原则</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">辰汇作为个性化资讯获取与交流平台</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">将在责任范围内最大程度地尊重与保护每一位用户的合法权益</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">辰汇亦未与任何第三方的个人或机构合作开展此项业务</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">以下所有规则与处理流程</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">以辰汇官方渠道</span><span
            style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">包括但不限于站内官方认证人员私信</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">官方邮箱</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">联系电话等</span><span style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">的回应为准</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">4.2 </span><span
            style="font-family:'Microsoft JhengHei'">受理范围</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">受理辰汇站内侵犯个人或组织机构合法权益的侵权举报</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">包括但不限于恶意抢注</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">冒充身份信息</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">侵犯个人隐私</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">造谣与诽谤以及商业侵权</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">4.2.1 </span><span
            style="font-family:'Microsoft JhengHei'">恶意抢注或冒充身份信息</span><span
            style="font-family:'Courier New'">：</span><span style="font-family:'Microsoft JhengHei'">辰汇注册</span><span
            style="font-family:'Courier New'">ID</span><span
            style="font-family:'Microsoft JhengHei'">信息恶意抢注或冒充他人或其他组织机构</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">包括但不限于冒用他人姓名</span><span
            style="font-family:'Courier New'">/</span><span style="font-family:'Microsoft JhengHei'">其他组织机构名称</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">头像</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">昵称等发布信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">并导致其他用户误认</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">4.2.2 </span><span
            style="font-family:'Microsoft JhengHei'">侵犯个人隐私</span><span style="font-family:'Courier New'">：</span><span
            style="font-family:'Microsoft JhengHei'">发布内容中直接涉及他人身份信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">如个人姓名</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">家庭住址</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">身份证号码</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">工作单位</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">私人电话等</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">4.2.3 </span><span
            style="font-family:'Microsoft JhengHei'">造谣与诽谤</span><span style="font-family:'Courier New'">：</span><span
            style="font-family:'Microsoft JhengHei'">发布内容中指名道姓</span><span style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">包括针对个人与组织机构</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">的直接谩骂</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">侮辱</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">虚构中伤</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">恶意诽谤等</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">4.2.4 </span><span style="font-family:'Microsoft JhengHei'">商业侵权</span><span
            style="font-family:'Courier New'">：</span><span style="font-family:'Microsoft JhengHei'">泄露企业商业秘密及其他根据保密协议不能公开传播的信息</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">4.3 </span><span
            style="font-family:'Microsoft JhengHei'">举报条件</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">若个人或组织机构发现辰汇上存在侵犯其自身合法权益的内容</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">可通过邮箱</span><span
            style="font-family:'Courier New'">（info@suniversgroup.com)</span><span
            style="font-family:'Microsoft JhengHei'">或电话</span><span
            style="font-family:'Courier New'">（0755 8254 2536）</span><span
            style="font-family:'Microsoft JhengHei'">与辰汇联系</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">为了保证问题能够得到及时有效的处理</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">请务必提交真实有效</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">完整清晰的证明材料</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">否则辰汇将不予受理</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">具体格式及规定如下</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">4.3.1 </span><span style="font-family:'Microsoft JhengHei'">权利人对涉嫌侵犯的信息或内容拥有商标权</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">著作权或其他依法可以行使权利的权属证明文件</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">若举报人非权利人</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">请举报人提供代表权利人进行举报的书面授权证明</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">4.3.2 </span><span style="font-family:'Microsoft JhengHei'">对侵犯了权利人合法权益的内容做充分</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">明确的描述</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">提供涉嫌侵犯个人信息或内容在辰汇上的具体页面地址</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">指明涉嫌侵权内容中的哪些信息内容侵犯了上述列明的权利人的合法权益</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">4.3.3 </span><span
            style="font-family:'Microsoft JhengHei'">权利人的具体联络信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">包括姓名</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">身份证或护照复印件</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">针对自然人</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">单位登记证明扫描件</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">通信地址</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">电话号码</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">电子邮件</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">4.3.4 </span><span style="font-family:'Microsoft JhengHei'">在侵权举报中加入如下关于举报内容真实性的声明</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">我本人为涉及侵权的信息内容的合法权利人</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">我举报辰汇站内的注册用户信息或其发布的内容侵犯了本人相应的合法权益</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">如果本侵权举报内容不完全属实</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">本人将承担由此产生的一切法律责任</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">4.4 </span><span
            style="font-family:'Microsoft JhengHei'">处理流程</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">举报人需明白</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">出于网络平台的监督属性</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并非所有申请都必须受理</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">辰汇将在收到举报材料七个工作日内进行审核并给予回复</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-left:18.9pt; margin-bottom:0pt; text-indent:-18.9pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">*</span><span style="font:7pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
            style="font-family:'Microsoft JhengHei'">举报人需明白</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">作为个性化资讯获取与交流平台</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">辰汇允许不同的观点与声音存在</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">任何个人或组织机构不得以</span><span
            style="font-family:'Courier New'">“</span><span style="font-family:'Microsoft JhengHei'">负面</span><span
            style="font-family:'Courier New'">”</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Courier New'">“</span><span style="font-family:'Microsoft JhengHei'">不客观</span><span
            style="font-family:'Courier New'">”</span><span style="font-family:'Microsoft JhengHei'">等模糊性表述为由要求辰汇受理删改内容或撤稿等申请</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">若个人或组织机构认为信息内容数据失实或与事实不符</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">需指明具体哪些内容中数据失实或与事实不符</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并提供相应的证明材料</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">统一提交至邮箱</span><span
            style="font-family:'Courier New'">（info@suniversgroup.com）</span><span
            style="font-family:'Microsoft JhengHei'">后申请受理</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">五</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">免责声明</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">5.1 </span><span
            style="font-family:'Microsoft JhengHei'">作为个性化的资讯信息获取与交流平台</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">辰汇有权但无义务对用户发布的所有内容进行审核</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">5.2 </span><span
            style="font-family:'Microsoft JhengHei'">针对辰汇用户创作</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">发布的信息内容</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">辰汇并不能保证其真实性和准确性</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">发布这些信息也不代表辰汇证实其描述或赞同其观点</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">5.3 </span><span
            style="font-family:'Microsoft JhengHei'">辰汇提供的所有信息内容仅供用户参考与交流</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">不构成商业建议或其他实际的操作意见</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">辰汇有权根据相关证据并结合</span><span
            style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">中华人民共和国侵权责任法</span><span
            style="font-family:'MS Gothic'">》、《</span><span
            style="font-family:'Microsoft JhengHei'">信息网络传播权保护条例</span><span style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">等法律法规对侵权信息进行处理</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">5.4 </span><span
            style="font-family:'Microsoft JhengHei'">辰汇并不保证其所提供的网络服务一定能满足用户的需求</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">也不保证其所提供的网络服务不会中断</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">对于因不可抗力或辰汇不能控制的其他原因造成的网络服务中断或其它问题</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">辰汇不承担任何责任</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">但承诺会尽量减少因此给用户造成的影响和损失</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">5.5 </span><span
            style="font-family:'Microsoft JhengHei'">用户知晓并且同意</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">对于因为不可抗力或辰汇不能控制的其他原因</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">如网络安全漏洞</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">黑客攻击等</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">导致的用户资料意外泄漏</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">辰汇不承担相应责任</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">但承诺会尽量减少因此给用户造成的影响和损失</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">5.6 </span><span
            style="font-family:'Microsoft JhengHei'">鉴于互联网服务的特殊性</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">用户同意辰汇有权随时变更</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">中断或终止其提供的部分或全部网络服务</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">如需变更</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">中断或终止其所提供的网络服务</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">辰汇无需通知用户</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">也无需对任何用户或任何第三方承担任何责任</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">六</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">协议修改</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">6.1 </span><span
            style="font-family:'Microsoft JhengHei'">根据互联网相关法律</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">法规及规范性文件的变化</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">或因业务调整与发展需要</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">辰汇有权在必要时通过在网页上发出公告等合理方式修改本服务协议以及各单项服务的相关协议</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">用户在享受各项服务时</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">应当及时查阅并了解相关协议的修改内容</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">并自觉遵守本服务协议以及其他单项服务的相关协议</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">用户如继续使用本协议所涉及的服务</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">则视为对修改内容的同意</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">用户在不同意修改内容的情况下</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">有权停止使用本服务协议涉及的服务</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">6.2 </span><span
            style="font-family:'Microsoft JhengHei'">在相关法律法规允许的最大范围内</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">辰汇保留对本用户协议的最终解释权</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">七</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">法律适用</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">7.1 </span><span
            style="font-family:'Microsoft JhengHei'">本协议的订立</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">执行和解释及争议的解决均应适应中国法律</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">7.2 </span><span
            style="font-family:'Microsoft JhengHei'">如双方就本协议内容或其执行发生争议</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">双方应尽量友好协商解决</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">协商不成时应</span><span
            style="font-family:'MS Gothic'">【</span><span
            style="font-family:'Microsoft JhengHei'">提请中国国际经济贸易仲裁委员会仲裁</span><span
            style="font-family:'MS Gothic'">】</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">仲裁裁决是终局性的</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">对双方均有约束力</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">隐私政策</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">深圳市前海辰宇投资咨询有限公司</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">以下统称</span><span
            style="font-family:'Courier New'">“</span><span style="font-family:'Microsoft JhengHei'">辰汇</span><span
            style="font-family:'Courier New'">”</span><span style="font-family:'Microsoft JhengHei'">或</span><span
            style="font-family:'Courier New'">“</span><span style="font-family:'Microsoft JhengHei'">我们</span><span
            style="font-family:'Courier New'">”）</span><span
            style="font-family:'Microsoft JhengHei'">一向庄严承诺保护使用辰汇的产品和服务</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">以下统称</span><span
            style="font-family:'Courier New'">“</span><span style="font-family:'Microsoft JhengHei'">辰汇服务</span><span
            style="font-family:'Courier New'">”）</span><span style="font-family:'Microsoft JhengHei'">之用户</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">以下统称</span><span
            style="font-family:'Courier New'">“</span><span style="font-family:'Microsoft JhengHei'">用户</span><span
            style="font-family:'Courier New'">”</span><span style="font-family:'Microsoft JhengHei'">或</span><span
            style="font-family:'Courier New'">“</span><span style="font-family:'Microsoft JhengHei'">您</span><span
            style="font-family:'Courier New'">”）</span><span style="font-family:'Microsoft JhengHei'">的隐私</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">您在使用辰汇服务时</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们可能会收集和使用您的相关信息</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">本</span><span style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">隐私政策</span><span style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">适用于我们提供的一切辰汇服务</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">当您使用我们任何单项服务时</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">您同意接受本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">以及我们在该单项服务中发出的特定隐私信息类政策条款</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">下列称</span><span
            style="font-family:'Courier New'">“</span><span style="font-family:'Microsoft JhengHei'">特定条款</span><span
            style="font-family:'Courier New'">”）</span><span style="font-family:'Microsoft JhengHei'">的保护</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">在此情况下特定条款与本政策条款同时对您产生效力</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">例如</span><span
            style="font-family:'Courier New'">：</span><span
            style="font-family:'Microsoft JhengHei'">使用辰汇学访服务的用户</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">同时适用本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Courier New'">；</span><span
            style="font-family:'Microsoft JhengHei'">又如</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">使用辰汇</span><span style="font-family:'Courier New'">APP</span><span
            style="font-family:'Microsoft JhengHei'">的用户</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">同时适用本</span><span style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">隐私政策</span><span style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">以及</span><span style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">辰汇用户注册协议</span><span style="font-family:'MS Gothic'">》。</span><span
            style="font-family:'Microsoft JhengHei'">如特定条款与本</span><span style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">隐私政策</span><span style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">条款存在同类条款的不一致约定</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">则在特定条款约束范围内应以特定条款为准</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">如我们提供的某一单项服务不适用本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">的</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们在该服务中会以适当方式明示排除适用本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">请注意我们不时地会检查我们的政策</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">因此有关的措施会随之变化</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">我们恳请您定期光顾本页面</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">以确保对我们</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">最新版本始终保持了解</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">在阅读完本政策之后</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">如您对本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">或与本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">相关的事宜有任何问题</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">请与</span><span
            style="font-family:'Courier New'">info@suniversgroup.com</span><span style="font-family:'Microsoft JhengHei'">联系</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">如果您使用或继续使用辰汇服务</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">这都表示您同意我们按照本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">收集</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">使用</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">储存和分享您的信息</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">一</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">我们可能收集的信息</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">一</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">与个人身份无关的信息</span><span style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">当您使用辰汇服务时</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们可能收集和汇总诸如用户的来源途径</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">访问顺序等信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">例如记录使用辰汇服务的每个用户的来源途径</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">浏览器软件等</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">二</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">有关个人身份的信息</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">当您使用辰汇服务时</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们可能收集和汇总或要求您提供有关个人身份的信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">例如个人身份证明</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">包括身份证</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">护照</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">驾驶证等</span><span
            style="font-family:'Courier New'">）；</span><span style="font-family:'Microsoft JhengHei'">生日</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">籍贯</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">性别</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">兴趣爱好</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">个人电话号码</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">面部特征</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">网络身份标识信息</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">包括系统账号</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Courier New'">IP</span><span
            style="font-family:'Microsoft JhengHei'">地址</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">电子邮箱地址及与前述有关的密码</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">口令</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">口令保护答案等</span><span
            style="font-family:'Courier New'">）；</span><span style="font-family:'Microsoft JhengHei'">个人财产信息</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">交易记录</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">余额</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">优惠券</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">游戏类兑换码</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">银行卡信息等</span><span
            style="font-family:'Courier New'">）；</span><span style="font-family:'Microsoft JhengHei'">通讯信息</span><span
            style="font-family:'Courier New'">；</span><span
            style="font-family:'Microsoft JhengHei'">个人上网记录和日志信息</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">包括网站浏览记录</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">软件使用记录</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">点击记录</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">操作日志等</span><span
            style="font-family:'Courier New'">）；</span><span style="font-family:'Microsoft JhengHei'">设备信息</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">包括设备型号</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">设备</span><span
            style="font-family:'Courier New'">MAC</span><span style="font-family:'Microsoft JhengHei'">地址</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">操作系统类型</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">设备设置</span><span
            style="font-family:'Courier New'">）；</span><span
            style="font-family:'Microsoft JhengHei'">软件列表唯一设备识别码</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">如</span><span
            style="font-family:'Courier New'">IMEI/android ID/IDFA/OPENUDID/GUID</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Courier New'">SIM</span><span
            style="font-family:'Microsoft JhengHei'">卡</span><span style="font-family:'Courier New'">IMSI</span><span
            style="font-family:'Microsoft JhengHei'">信息等在内的描述个人常用设备基本情况的信息</span><span style="font-family:'Courier New'">）；</span><span
            style="font-family:'Microsoft JhengHei'">位置信息</span><span style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">包括行程信息</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">精准定位信息</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">住宿信息</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">经纬度等</span><span style="font-family:'Courier New'">）</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">我们的产品集成环信需要手机您的设备</span><span
            style="font-family:'Courier New'">Mac</span><span style="font-family:'Microsoft JhengHei'">地址</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">唯一设备识别码</span><span
            style="font-family:'Courier New'">（IMEI/android ID/IDFA/OPENUDID/GUID</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Courier New'">SIM</span><span
            style="font-family:'Microsoft JhengHei'">卡</span><span style="font-family:'Courier New'">IMSI</span><span
            style="font-family:'Microsoft JhengHei'">信息</span><span style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">以提供统计分析服务</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并通过地理位置校准报表数据准确性</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">提供基础反作弊能力</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们收集您的信息主要是为了您和其他用户能够更容易和更满意地使用辰汇服务</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">辰汇的目标是向所有的互联网用户提供安全</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">刺激</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">有趣及有教益的上网经历</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">而这些信息有助于我们实现这一目标</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">请注意</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">如您在辰汇服务中其他用户可见的公开区域内上传或发布的信息中</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">您对其他人上传或发布的信息作出的回应中公开您的个人信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">该等信息可能会被他人收集并加以使用</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">当您发现辰汇的用户不正当地收集或使用您或他人的信息时</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">请联系</span><span
            style="font-family:'Courier New'">info@suniversgroup.com</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">二</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">我们如何收集和使用信息</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">一</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">我们将通过以下途径收集和获得您的信息</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">1</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">您提供的信息</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">例如</span><span style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（1）</span><span
            style="font-family:'Microsoft JhengHei'">您在注册辰汇服务的账号或使用辰汇服务时</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">向我们提供的信息</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（2）</span><span
            style="font-family:'Microsoft JhengHei'">您通过辰汇服务向其他方提供的共享信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">以及您使用辰汇服务时所储存的信息</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">2</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">其他方分享的您的信息</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">亦即其他方使用辰汇服务时所提供有关您的共享信息</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">3</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">我们获取的您的信息</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">您在使用辰汇服务时</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们收集</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">汇总</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">记录的信息</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">例如日志信息</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">位置信息</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">设备信息等</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">二</span><span
            style="font-family:'Courier New'">）COOKIES</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">日志档案和</span><span style="font-family:'Courier New'">WEB BEACON</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们或我们的第三方合作伙伴可能通过</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span
            style="font-family:'Microsoft JhengHei'">获取和使用您的信息</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并将该等信息储存为日志信息</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">通过使用</span><span
            style="font-family:'Courier New'">COOKIES，</span><span style="font-family:'Microsoft JhengHei'">我们向用户提供简单易行并富个性化的网络体验</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">一个</span><span
            style="font-family:'Courier New'">COOKIES</span><span
            style="font-family:'Microsoft JhengHei'">是少量的数据</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">它们从一个网络服务器送至您的浏览器并存在计算机硬盘上</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">我们使用</span><span
            style="font-family:'Courier New'">COOKIES</span><span
            style="font-family:'Microsoft JhengHei'">是为了让其用户可以受益</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">比如</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">为使得辰汇登录过程更快捷</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您可以选择把用户名存在一个</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">中</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">这样下次当您要登录辰汇的服务时能更加方便快捷</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Courier New'">COOKIES</span><span
            style="font-family:'Microsoft JhengHei'">能帮助我们确定您连接的页面和内容</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">您在辰汇特定服务上花费的时间和您所选择的辰汇服务</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">COOKIES</span><span
            style="font-family:'Microsoft JhengHei'">使得我们能更好</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">更快地为您服务</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并且使您在辰汇服务上的经历更富个性化</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">然而</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">您应该能够控制</span><span
            style="font-family:'Courier New'">COOKIES</span><span
            style="font-family:'Microsoft JhengHei'">是否以及怎样被你的浏览器接受</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">请查阅您的浏览器附带的文件以获得更多这方面的信息</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们和第三方合作伙伴可能通过</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span
            style="font-family:'Microsoft JhengHei'">收集和使用您的信息</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并将该等信息储存</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们使用自己的</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON，</span><span
            style="font-family:'Microsoft JhengHei'">用于以下用途</span><span style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（1）</span><span style="font-family:'Microsoft JhengHei'">记住您的身份</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">例如</span><span
            style="font-family:'Courier New'">：COOKIES</span><span style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span style="font-family:'Microsoft JhengHei'">有助于我们辨认您作为我们的注册用户的身份</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">或保存您向我们提供有关您的喜好或其他信息</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（2）</span><span style="font-family:'Microsoft JhengHei'">分析您使用我们服务的情况</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">我们可利用</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span style="font-family:'Microsoft JhengHei'">来了解您使用辰汇服务进行什么活动</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">或哪些服务或服务最受欢迎</span><span style="font-family:'Courier New'">；</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（3）</span><span style="font-family:'Microsoft JhengHei'">广告优化</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Courier New'">COOKIES</span><span
            style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span
            style="font-family:'Microsoft JhengHei'">有助于我们根据您的信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">向您提供与您相关的广告而非进行普遍的广告投放</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们为上述目的使用</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span
            style="font-family:'Microsoft JhengHei'">的同时</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">可能将通过</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span style="font-family:'Microsoft JhengHei'">收集的非个人身份信息汇总提供给广告商和其他伙伴</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">用于分析您和其他用户如何使用辰汇服务并用于广告服务</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">辰汇服务上可能会有广告商和其他合作方放置的</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">这些</span><span style="font-family:'Courier New'">COOKIES</span><span
            style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span style="font-family:'Microsoft JhengHei'">可能会收集与您相关的非个人身份信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">以用于分析用户如何使用该等服务</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">向您发送您可能感兴趣的广告</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">或用于评估广告服务的效果</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">这些第三方</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span
            style="font-family:'Microsoft JhengHei'">收集和使用该等信息不受本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">约束</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">而是受到其自身的个人信息保护声明约束</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们不对第三方的</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">或</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span
            style="font-family:'Microsoft JhengHei'">承担责任</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">您可以通过浏览器或用户选择机制拒绝或管理</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">或</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">但请您注意</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">如果您停用</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">或</span><span
            style="font-family:'Courier New'">WEB BEACON，</span><span style="font-family:'Microsoft JhengHei'">我们有可能无法为您提供最佳的服务体验</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">某些服务也可能无法正常使用</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">同时</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">您仍然将收到广告</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">只是这些广告与您的相关性会降低</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">三</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">我们会出于以下目的</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">收集和使用您的信息</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">1</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">帮助您完成注册</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">为便于我们为您提供服务</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您需要提供基本注册信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">例如手机号码</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">电子邮箱地址等</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并创建您的用户名和密码</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">在部分单项服务中</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">如果您仅需使用浏览</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">搜索等基本服务</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您不需要注册成为辰汇用户及提供上述信息</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">2</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">向您提供商品或服务</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们所收集和使用的信息是为您提供辰汇服务的必要条件</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">如缺少相关信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们将无法为您提供辰汇服务的核心内容</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">例如</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（1）</span><span
            style="font-family:'Microsoft JhengHei'">在部分服务项目中</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">为便于向您交付商品或服务</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您需提供收货人个人身份信息</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">姓名</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">收货地址</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">邮政编码</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">收货人</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">联系电话</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">支付状态等信息</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">如果您拒绝提供此类信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们将无法完成相关交付服务</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">如您通过辰汇服务为其他人订购商品或服务</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您需要提供该实际订购人的前述信息</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">向我们提供该实际订购人的前述信息之前</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">您需确保您已经取得其授权同意</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（2）</span><span
            style="font-family:'Microsoft JhengHei'">为展示您账户的订单信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们会收集您在使用辰汇服务过程中产生的订单信息用于向您展示及便于您对订单进行管理</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（3）</span><span
            style="font-family:'Microsoft JhengHei'">当您与我们联系时</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们可能会保存您的通信</span><span
            style="font-family:'Courier New'">/</span><span
            style="font-family:'Microsoft JhengHei'">通话记录和内容或您留下的联系方式等信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">以便与您联系或帮助您解决问题</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">或记录相关问题的处理方案及结果</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（4）</span><span style="font-family:'Microsoft JhengHei'">为确认交易状态及为您提供售后与争议解决服务</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们会通过您基于交易所选择的交易对象</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">支付机构</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">物流公司等收集与交易进度相关的您的交易</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">支付</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">物流信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">或将您的交易信息共享给上述服务提供者</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">3</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">向您推送消息</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（1）</span><span style="font-family:'Microsoft JhengHei'">为您展示和推送商品或服务</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">我们可能使用您的信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">您的浏览及搜索记录</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">设备信息</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">位置信息</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">订单信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">提取您的浏览</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">搜索偏好</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">行为习惯</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">位置信息等特征</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并基于特征标签通过电子邮件</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">短信或其他方式向您发送营销信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">提供或推广我们或第三方的如下商品和服务</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们的商品和服务</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">包括但不限于</span><span
            style="font-family:'Courier New'">：</span><span style="font-family:'Microsoft JhengHei'">即时通讯服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">网上媒体服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">互动娱乐服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">社交网络服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">支付服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">搜索服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">位置服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">应用软件和服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">数据管理软件和服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">网上广告服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">社交媒体</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">电子商务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">资讯及通讯软件和服务</span><span
            style="font-family:'Courier New'">（“</span><span style="font-family:'Microsoft JhengHei'">互联网服务</span><span
            style="font-family:'Courier New'">”）；</span><span
            style="font-family:'Microsoft JhengHei'">及第三方商品和服务</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">包括但不限于</span><span style="font-family:'Courier New'">：</span><span
            style="font-family:'Microsoft JhengHei'">互联网服务</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">食物和餐饮</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">体育</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">音乐</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">电影</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">电视</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">现场表演和其他艺术和娱乐</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">书册</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">杂志和其他刊物</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">服装和配饰</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">珠宝</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">化妆品</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">个人健康和卫生</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">电子</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">收藏品</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">家用器皿</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">电器</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">家居装饰和摆设</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">宠物</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">汽车</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">酒店</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">交通和旅游</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">银行</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">保险及金融服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">积分和奖励计划</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">以及我们认为可能与您相关的其他商品和服务</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（2）</span><span style="font-family:'Microsoft JhengHei'">向您发出通知</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">我们可能在必需时</span><span
            style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">例如当我们由于系统维护而暂停某一单项服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">变更</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">终止提供某一单项服务时</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">向您发出与服务有关的通知</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">如您不希望继续接收我们推送的消息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您可要求我们停止推送</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">例如</span><span
            style="font-family:'Courier New'">：</span><span
            style="font-family:'Microsoft JhengHei'">根据短信退订指引要求我们停止发送推广短信</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">或在移动端设备中进行设置</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">不再接收我们推送的消息等</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">但我们依法律规定或单项服务的服务协议约定发送消息的情形除外</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">4</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">为您提供安全保障</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">为确保您身份真实性</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">向您提供更好的安全保障</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您可以向我们提供身份证明</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">面部特征等生物识别信息等个人敏感信息以完成实名认证</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">除身份验证外</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们可能将您的信息用于客户服务</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">安全防范</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">诈骗监测</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">存档和备份等用途</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">确保我们向您提供的服务的安全性</span><span
            style="font-family:'Courier New'">；</span><span
            style="font-family:'Microsoft JhengHei'">我们可能使用或整合我们所收集的您的信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">以及我们的合作伙伴取得您授权或依据法律共享的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">来综合判断您账户及交易风险</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">进行身份验证</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">检测及防范安全事件</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并依法采取必要的记录</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">审计</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">分析</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">处置措施</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">5</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">改进我们的服务</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们可能将通过某一项辰汇服务所收集的信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">用于我们的其他服务</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">例如</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">在您使用某一项辰汇服务时所收集的您的信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">可能在另一项辰汇服务中用于向您提供特定内容或向您展示与您相关的</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">而非普遍推送的信息</span><span
            style="font-family:'Courier New'">；</span><span
            style="font-family:'Microsoft JhengHei'">我们可能让您参与有关辰汇服务的调查</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">帮助我们改善现有服务或设计新服务</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">同时</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们可能将您的信息用于软件更新</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">您了解并同意</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">在收集您的信息后</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们将通过技术手段对数据进行去标识化处理</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">去标识化处理的信息将无法识别您的身份并且被处理信息不能复原</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">在此情况下我们有权使用已经去标识化的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">对用户数据库进行分析并予以商业化的利用</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">如果我们将您的信息用于本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">中未载明的其他用途</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">会事先征求您同意</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">6</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">征得授权同意的例外</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">根据相关法律法规规定</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">以下情形中收集您的信息无需征得您的授权同意</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（1）</span><span style="font-family:'Microsoft JhengHei'">与国家安全</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">国防安全有关的</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（2）</span><span style="font-family:'Microsoft JhengHei'">与公共安全</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">公共卫生</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">重大公共利益有关的</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（3）</span><span style="font-family:'Microsoft JhengHei'">与犯罪侦查</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">起诉</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">审判和判决执行等有关的</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（4）</span><span
            style="font-family:'Microsoft JhengHei'">出于维护信息主体或其他个人的生命</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">财产等重大合法权益但又很难得到您本人同意的</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（5）</span><span
            style="font-family:'Microsoft JhengHei'">所收集的信息是您自行向社会公众公开的</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（6）</span><span
            style="font-family:'Microsoft JhengHei'">从合法公开披露的信息中收集信息的</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">如合法的新闻报道</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">政府信息公开等渠道</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（7）</span><span
            style="font-family:'Microsoft JhengHei'">根据您的要求签订合同所必需的</span><span style="font-family:'Courier New'">；</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（8）</span><span
            style="font-family:'Microsoft JhengHei'">用于维护辰汇服务的安全稳定运行所必需的</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">例如发现</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">处置产品或服务的故障</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（9）</span><span style="font-family:'Microsoft JhengHei'">为合法的新闻报道所必需的</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">（10）</span><span
            style="font-family:'Microsoft JhengHei'">学术研究机构基于公共利益开展统计或学术研究所必要</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">且对外提供学术研究或描述的结果时</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">对结果中所包含的信息进行去标识化处理的</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Courier New'">（11）</span><span
            style="font-family:'Microsoft JhengHei'">法律法规规定的其他情形</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">三</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">我们可能分享</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">转让或披露的信息</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">一</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">分享</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">除以下情形外</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">未经您同意</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们不会与辰汇之外的任何第三方分享您的信息</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">1</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">向您提供我们的服务</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">我们可能向合作伙伴及其他第三方分享您的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">以实现您需要的核心功能或提供您需要的服务</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">例如</span><span
            style="font-family:'Courier New'">：</span><span
            style="font-family:'Microsoft JhengHei'">向物流服务商提供对应的订单信息</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">2</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">维护和改善我们的服务</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">我们可能向合作伙伴及其他第三方分享您的信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">以帮助我们为您提供更有针对性</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">更完善的服务</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">例如</span><span
            style="font-family:'Courier New'">：</span><span style="font-family:'Microsoft JhengHei'">代表我们发出电子邮件或推送通知的通讯服务提供商等</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">3</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">实现本</span><span style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">隐私政策</span><span style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">第二条</span><span style="font-family:'Courier New'">“</span><span
            style="font-family:'Microsoft JhengHei'">我们如何收集和使用信息</span><span
            style="font-family:'Courier New'">”</span><span style="font-family:'Microsoft JhengHei'">部分所述目的</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">4</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">履行我们在本</span><span style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">隐私政策</span><span style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">或我们与您达成的其他协议中的义务和行使我们的权利</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">5</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">向委托我们进行推广的合作伙伴等第三方共享</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">但我们仅会向这些委托方提供推广的覆盖面和有效性的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">而不会提供可以识别您身份的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">例如姓名电话号码或电子邮箱</span><span
            style="font-family:'Courier New'">；</span><span
            style="font-family:'Microsoft JhengHei'">或者我们将这些信息进行汇总</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">以便它不会识别您个人</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">或者向他们提供不能识别个人身份的统计信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">帮助他们了解其受众或顾客</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">6</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">在法律法规允许的范围内</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">为了遵守法律</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">维护我们及我们的关联方或合作伙伴</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">您或其他辰汇用户或社会公众利益</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">财产或安全免遭损害</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">比如为防止欺诈等违法活动和减少信用风险</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们可能与其他公司和组织交换信息</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">不过</span><span style="font-family:'Courier New'">,</span><span
            style="font-family:'Microsoft JhengHei'">这并不包括违反本</span><span style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">隐私政策</span><span style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">中所作的承诺而为获利目的出售</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">出租</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">共享或以其它方式披露的信息</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">7</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">应您合法需求</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">协助处理您与他人的纠纷或争议</span><span style="font-family:'Courier New'">；</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">8</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">应您的监护人合法要求而提供您的信息</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">9</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">根据与您签署的单项服务协议</span><span
            style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">包括在线签署的电子协议以及相应的平台规则</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">或其他的法律文件约定所提供</span><span style="font-family:'Courier New'">；</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">10</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">基于学术研究而提供</span><span style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">11</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">基于符合法律法规的社会公共利益而提供</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们仅会出于合法</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">正当</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">必要</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">特定</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">明确的目的共享您的信息</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">对我们与之共享信息的公司</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">组织和个人</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们会与其签署严格的保密协定</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">要求他们按照我们的说明</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">本</span><span style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">隐私政策</span><span style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">以及其他任何相关的保密和安全措施来处理信息</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">二</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">转让</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">1</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">随着我们业务的持续发展</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们有可能进行合并</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">收购</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">资产转让或类似的交易</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">而您的信息有可能作为此类交易的一部分而被转移</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">我们会要求新的持有您信息的公司</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">组织继续受本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">的约束</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">否则</span><span
            style="font-family:'Courier New'">,</span><span style="font-family:'Microsoft JhengHei'">我们将要求该公司</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">组织重新向您征求授权同意</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">2</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">在获得您的明确同意后</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们会向其他方转让您的信息</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">三</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">披露</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们仅会在以下情况下</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">且采取符合业界标准的安全防护措施的前提下</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">才会披露您的信息</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">1</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">根据您的需求</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">在您明确同意的披露方式下披露您所指定的信息</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">2</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">根据法律</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">法规的要求</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">强制性的行政执法或司法要求所必须提供您信息的情况下</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们可能会依据所要求的信息类型和披露方式披露您的信息</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">在符合法律法规的前提下</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">当我们收到上述披露信息的请求时</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们会要求接收方必须出具与之相应的法律文件</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">如传票或调查函</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">我们坚信</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">对于要求我们提供的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">应该在法律允许的范围内尽可能保持透明</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">我们对所有的请求都进行了慎重的审查</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">以确保其具备合法依据</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">且仅限于执法部门因特定调查目的且有合法权利获取的数据</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">四</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">我们如何保留</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">储存和保护信息</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们仅在本政策所述目的所必需的期间和法律法规及监管规定要求的时限内保留您的个人信息</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">我们将会按照</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">网络安全法</span><span
            style="font-family:'MS Gothic'">》、《</span><span style="font-family:'Microsoft JhengHei'">个人信息保护法</span><span
            style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">等相关法律法规规定的期限保存您的个人信息</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">超出法定保存期限后</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们会将您的个人信息删除或做匿名化处理</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们在中华人民共和国境内运营中收集和产生的信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">存储在中国境内</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">以下情形除外</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">1</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">法律法规有明确规定</span><span style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">2</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">获得您的授权同意</span><span style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">3</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">您使用的产品</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">服务涉及跨境</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">辰汇需要向境外提供您的个人信息的</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">针对以上情形</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们将采取以下手段保护您的信息</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">一</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">数据安全技术措施</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们会采用符合业界标准的安全防护措施</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">包括建立合理的制度规范</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">安全技术来防止您的信息遭到未经授权的访问使用</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">修改</span><span
            style="font-family:'Courier New'">,</span><span
            style="font-family:'Microsoft JhengHei'">避免数据的损坏或丢失</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">网络服务采取了多种加密技术</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">例如在某些服务中</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们将利用加密技术</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">例如</span><span
            style="font-family:'Courier New'">SSL）</span><span
            style="font-family:'Microsoft JhengHei'">来保护您的信息</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">采取加密技术对您的信息进行加密保存</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并通过隔离技术进行隔离</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">在信息使用时</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">例如信息展示</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">信息关联计算</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们会采用多种数据脱敏技术增强信息在使用中安全性</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">采用严格的数据访问权限控制和多重身份认证技术保护信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">避免数据被违规使用</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">二</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">我们为保护信息采取的其他安全措施</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们通过建立数据分类分级制度</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">数据安全管理规范</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">数据安全开发规范来管理规范信息的存储和使用</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们通过信息接触者保密协议</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">监控和审计机制来对数据进行全面安全控制</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">加强安全意识</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">我们还会举办安全和隐私保护培训课程</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">加强员工对于保护信息重要性的认识</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">三</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">我们仅允许有必要知晓这些信息的辰汇员工</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">合作伙伴访问您的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并为此设置了严格的访问权限控制和监控机制</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">我们同时要求可能接触到您的信息的所有人员履行相应的保密义务</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">如果未能履行这些义务</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">可能会被追究法律责任或被中止与辰汇的合作关系</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">四</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">我们会采取一切合理可行的措施</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">确保未收集无关的信息</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">五</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">互联网并非绝对安全的环境</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">而且电子邮件</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">即时通讯</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们建议您使用此类工具时请使用复杂密码</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并注意保护您的信息安全</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">六</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">互联网环境并非百分之百安全</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们将尽力确保或担保您发送给我们的任何信息的安全性</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">如果我们的物理</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">技术</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">或管理防护设施遭到破坏</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">导致信息被非授权访问</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">公开披露</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">篡改</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">或毁坏</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">导致您的合法权益受损</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们将承担相应的法律责任</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">七</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">安全事件处置</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">在通过辰汇服务与第三方进行沟通或购买商品及服务时</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">您不可避免的要向交易对方或潜在的交易对方披露自己的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">如联络方式或者邮政地址等</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">请您妥善保护自己的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">仅在必要的情形下向他人提供</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">为应对信息泄露</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">损毁和丢失等可能出现的风险</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们制定了多项制度</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">明确安全事件</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">安全漏洞的分类分级标准及相应的处理流程</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">我们也为安全事件建立了专门的应急响应团队</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">按照安全事件处置规范要求</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">针对不同安全事件启动安全预案</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">进行止损</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">分析</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">定位</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">制定补救措施</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">联合相关部门进行溯源和打击</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">在不幸发生信息安全事件后</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们将按照法律法规的要求</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">及时向您告知</span><span
            style="font-family:'Courier New'">：</span><span
            style="font-family:'Microsoft JhengHei'">安全事件的基本情况和可能的影响</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">我们已采取或将要采取的处置措施</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">您可自主防范和降低风险的建议</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">对您的补救措施等</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">我们同时将及时将事件相关情况以邮件</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">信函</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">电话</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">推送通知等方式告知您</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">难以逐一告知信息主体时</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们会采取合理</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">有效的方式发布公告</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">同时</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们还将按照监管部门要求</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">主动上报信息安全事件的处置情况</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">请您理解</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">由于技术的限制以及风险防范的局限</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">即便我们已经尽量加强安全措施</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">也无法始终保证信息百分之百的安全</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">您需要了解</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您接入辰汇服务所用的系统和通讯网络</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">有可能因我们可控范围外的情况而发生问题</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">五</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">如何管理您的信息</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">一</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">访问</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">更新和删除</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们鼓励您更新和修改您的信息以使其更准确有效</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">您能通过辰汇服务访问您的信息</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并根据对应信息的管理方式自行完成或要求我们进行修改</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">补充和删除</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">我们将采取适当的技术手段</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">尽可能保证您可以访问</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">更新和更正自己的信息或使用辰汇服务时提供的其他信息</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">在访问</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">更新</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">更正和删除前述信息时</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们可能会要求您进行身份验证</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">以保障信息安全</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">对于通过</span><span
            style="font-family:'Courier New'">COOKIES</span><span style="font-family:'Microsoft JhengHei'">和</span><span
            style="font-family:'Courier New'">WEB BEACON</span><span
            style="font-family:'Microsoft JhengHei'">收集的您的信息</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们还在本</span><span style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">隐私政策</span><span style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">第二条</span><span
            style="font-family:'Courier New'">“COOKIES</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">日志档案和</span><span
            style="font-family:'Courier New'">WEB BEACON”</span><span style="font-family:'Microsoft JhengHei'">部分说明了向您提供的选择机制</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">二</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">公开与分享</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们的多项服务可让您不仅与您的社交网络</span><span
            style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">也与使用该服务的所有用户公开分享您的相关信息</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">例如</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您在辰汇服务中所上传或发布的信息</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">您对其他人上传或发布的信息作出的回应</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">通过电子邮件或在辰汇服务中不特定用户可见的公开区域内上传或公布您的个人信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">以及包括与这些信息有关的位置数据和日志信息</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">只要您不删除您所公开或共享的信息</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">有关信息可能一直留存在公众领域</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">即使您删除共享信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">有关信息仍可能由其他用户或不受我们控制的第三方独立地缓存</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">复制或储存</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">或由其他用户或该等第三方在公众领域保存</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">如您将信息通过上述渠道公开或共享</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">由此造成您的信息泄露</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们不承担责任</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">因此</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的信息</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">三</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">注销</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">在符合辰汇单项服务的服务协议约定条件及国家相关法律法规规定的情况下</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您的该项辰汇服务账号可能被注销或删除</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">当账号注销或被删除后</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">与该账号相关的</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或处理</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">您可以通过</span><span
            style="font-family:'Courier New'">App</span><span style="font-family:'Microsoft JhengHei'">我的</span><span
            style="font-family:'Courier New'"> -- </span><span style="font-family:'Microsoft JhengHei'">设置</span><span
            style="font-family:'Courier New'"> -- </span><span
            style="font-family:'Microsoft JhengHei'">账号绑定和安全</span><span
            style="font-family:'Courier New'"> -- </span><span
            style="font-family:'Microsoft JhengHei'">账号注销提交注销申请</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">注销账号将产生以下后果请您知晓</span><span
            style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">1</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">账号信息</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">第三方授权</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">认证身份将被清空和取消</span><span style="font-family:'Courier New'">；</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">2</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">所有使用</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">收藏</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">关注列表</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">购买记录和黑名单将被清空</span><span style="font-family:'Courier New'">；</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">3</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">账户中所有的嗅币和现金余额将被清空</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">4</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">账户对应的会员身份与权益将被取消</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">5</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">您在平台发布的内容将做匿名处理</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">一经确认</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">以上内容将无法恢复</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">请谨慎考虑后决定</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">根据相关法律法规</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">注销辰汇账号并不代表注销前的账号行为和相关责任得到豁免或减轻</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">提交注销申请后的</span><span
            style="font-family:'Courier New'"> 15 </span><span style="font-family:'Microsoft JhengHei'">日内</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">后台将进行审核工作</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">如无问题即可注销成功</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">在此期间如您登录</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">则视为取消注销申请</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">四</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">改变您授权同意的范围</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">您总是可以选择是否披露信息</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">有些信息是使用辰汇服务所必需的</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">但大多数其他信息的提供是由您决定的</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">您可以通过删除信息</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">关闭设备功能等方式改变您授权我们继续收集信息的范围或撤回您的授权</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">当您撤回授权后</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们无法继续为您提供撤回授权所对应的服务</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">也不再处理您相应的信息</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">但您撤回授权的决定</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">不会影响此前基于您的授权而开展的信息处理</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">五</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'Microsoft JhengHei'">有关敏感信息的提示</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">某些信息因其特殊性可能被认为是敏感信息</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">例如您的种族</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">宗教</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">个人健康和医疗信息等</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">以及身份证明文件</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">个人生物识别信息</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">财产信息</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">行踪轨迹</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">未成年人的信息等</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">请注意</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您在辰汇服务中所提供</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">上传或发布的内容和信息</span><span
            style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">例如有关您社交活动的照片或信息</span><span
            style="font-family:'Courier New'">），</span><span
            style="font-family:'Microsoft JhengHei'">可能会泄露您的敏感信息</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">您需要谨慎地考虑</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">是否使用辰汇服务披露您的敏感信息</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">您同意您的敏感信息按本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">所述的目的和方式来处理</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">六</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">第三方服务</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">辰汇服务可能链接至第三方提供的社交媒体或其他服务</span><span
            style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">包括网站或其他服务形式</span><span
            style="font-family:'Courier New'">）</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">包括</span><span style="font-family:'Courier New'">：</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（1）</span><span style="font-family:'Microsoft JhengHei'">您可利用</span><span
            style="font-family:'Courier New'">“</span><span style="font-family:'Microsoft JhengHei'">分享</span><span
            style="font-family:'Courier New'">”</span><span
            style="font-family:'Microsoft JhengHei'">键将某些内容分享到辰汇服务</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">或您可利用第三方服务登录辰汇服务</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">这些功能可能会收集您的信息</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">包括您的日志信息</span><span
            style="font-family:'Courier New'">），</span><span
            style="font-family:'Microsoft JhengHei'">并可能在您的电脑装置</span><span
            style="font-family:'Courier New'">COOKIES，</span><span
            style="font-family:'Microsoft JhengHei'">从而正常运行上述功能</span><span style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（2）</span><span style="font-family:'Microsoft JhengHei'">我们通过广告或我们服务的其他方式向您提供链接</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">使您可以接入第三方的服务或网站</span><span
            style="font-family:'Courier New'">；</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">（3）</span><span style="font-family:'Microsoft JhengHei'">其他接入第三方服务的情形</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">该等第三方社交媒体或其他服务由相关的第三方负责运营</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">您使用该等第三方的社交媒体服务或其他服务</span><span
            style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">包括您向该等第三方提供的任何信息</span><span
            style="font-family:'Courier New'">），</span><span
            style="font-family:'Microsoft JhengHei'">须受第三方自己的服务条款及信息保护声明</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">而非本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">约束</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您需要仔细阅读其条款</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">本</span><span style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">隐私政策</span><span style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">仅适用于我们所收集的任何信息</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并不适用于任何第三方提供的服务或第三方的信息使用规则</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">而我们对任何第三方使用由您提供的信息不承担任何责任</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">七</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">年龄限制</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">我们建议</span><span style="font-family:'Courier New'">：</span><span
            style="font-family:'Microsoft JhengHei'">任何未成年人参加网上活动应事先取得家长或其法定监护人</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">以下简称</span><span
            style="font-family:'Courier New'">"</span><span style="font-family:'Microsoft JhengHei'">监护人</span><span
            style="font-family:'Courier New'">"）</span><span style="font-family:'Microsoft JhengHei'">的同意</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">我们将根据国家相关法律法规的规定保护未成年人的相关信息</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们鼓励父母或监护人指导未成年人使用辰汇服务</span><span
            style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">我们建议未成年人鼓励他们的父母或监护人阅读本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并建议未成年人在提交信息之前寻求父母或监护人的同意和指导</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">如您为未成年人</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">应首先取得其监护人的同意</span><span
            style="font-family:'Courier New'">（</span><span style="font-family:'Microsoft JhengHei'">包括本政策</span><span
            style="font-family:'Courier New'">），</span><span
            style="font-family:'Microsoft JhengHei'">在监护人同意后和指导下进行使用</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">提交个人信息</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">如果您是</span><span style="font-family:'Courier New'">14</span><span
            style="font-family:'Microsoft JhengHei'">周岁以下儿童的</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">在您使用我们的服务前</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">请您通知您的监护人阅读我们的儿童个人信息保护规则</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">并请您在监护人的授权同意后</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">在监护人的指导下使用我们的产品与</span><span
            style="font-family:'Courier New'">/</span><span style="font-family:'Microsoft JhengHei'">或服务</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">对于经监护人同意使用我们的产品或服务而收集儿童个人信息的情况</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们只会在法律法规允许</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">监护人明确同意或者保护未成年人所必要的情况下使用</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">共享</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">转让或披露</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">八</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">通知和修订</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们可能适时修改本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">的条款</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">该等修改构成本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》</span><span style="font-family:'Microsoft JhengHei'">的一部分</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">对于重大变更</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">我们会提供更显著的通知</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您可以选择停止使用辰汇服务</span><span
            style="font-family:'Courier New'">；</span><span style="font-family:'Microsoft JhengHei'">在该种情况下</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">如您仍然继续使用辰汇服务的</span><span
            style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">即表示同意受经修订的本</span><span style="font-family:'MS Gothic'">《</span><span
            style="font-family:'Microsoft JhengHei'">隐私政策</span><span style="font-family:'MS Gothic'">》</span><span
            style="font-family:'Microsoft JhengHei'">的约束</span><span style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">任何修改都会将您的满意度置于首位</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">我们鼓励您在每次使用辰汇服务时都查阅我们的隐私政策</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">我们可能在必需时</span><span
            style="font-family:'Courier New'">（</span><span
            style="font-family:'Microsoft JhengHei'">例如当我们由于系统维护而暂停某一项服务时</span><span
            style="font-family:'Courier New'">）</span><span
            style="font-family:'Microsoft JhengHei'">发出与服务有关的公告</span><span style="font-family:'MS Gothic'">。</span><span
            style="font-family:'Microsoft JhengHei'">您可能无法取消这些与服务有关</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">性质不属于推广的公告</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">最后</span><span style="font-family:'Courier New'">，</span><span
            style="font-family:'Microsoft JhengHei'">您必须对您的账号和密码信息负有保密义务</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">任何情况下</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">请小心妥善保管</span><span
            style="font-family:'MS Gothic'">。</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Microsoft JhengHei'">九</span><span style="font-family:'MS Gothic'">、</span><span
            style="font-family:'Microsoft JhengHei'">如何联系我们</span></p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span style="font-family:'Microsoft JhengHei'">如您有关于网络信息安全的投诉和举报</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">或您对本</span><span
            style="font-family:'MS Gothic'">《</span><span style="font-family:'Microsoft JhengHei'">隐私政策</span><span
            style="font-family:'MS Gothic'">》、</span><span
            style="font-family:'Microsoft JhengHei'">您的信息的相关事宜有任何问题</span><span
            style="font-family:'MS Gothic'">、</span><span style="font-family:'Microsoft JhengHei'">意见或建议</span><span
            style="font-family:'Courier New'">，</span><span style="font-family:'Microsoft JhengHei'">以及有关本声明或辰汇的隐私措施的问题请与辰汇的协调人联系</span><span
            style="font-family:'MS Gothic'">。</span><span style="font-family:'Microsoft JhengHei'">请联系</span><span
            style="font-family:'Courier New'">：info@suniversgroup.com</span><span style="font-family:'MS Gothic'">。</span>
          </p>
          <p style="margin-top:0pt; margin-bottom:0pt; font-size:10.5pt"><span
            style="font-family:'Courier New'">&#xa0;</span></p></div>
      </body>
    </html>
  </div>
</template>

<script>
export default {
  name: 'Privacy'
}
</script>

<style scoped>

</style>
